var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    [
      _c("screens-filter", {
        attrs: { loadingScreens: _vm.loadingScreens },
        on: {
          value: function ($event) {
            _vm.filters = $event
          },
          searchData: _vm.updateScreens,
        },
      }),
      _c("screens-table", {
        attrs: { screens: _vm.screens, loadingScreens: _vm.loadingScreens },
        on: { updateData: _vm.updateScreens },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }