<template>
    <div class="centerx">
      <vs-popup 
        :title="title"
        :active.sync="poModalActive"
        @close="handleClose"
        class="modal-docs"
        id="show-docs-popup"
      >
        <!-- <h4 class="mb-4 text-lg text-bold text-black-light">Activos</h4> -->
        <files-table 
          :tableData="localData" 
          :variant="variant" 
          @successFileUpdate="successFileUpdate"
        />

        <!-- !!!!! When exist null field this code will be shown !!!!! -->

        <!-- <div v-if="variant === 'billing'">
          <vs-divider class="my-4">
            <h4 class="text-lg text-bold text-black-light">Anuladas</h4>
          </vs-divider>
          <files-table :tableData="localData.filter(item => item.null === true)" @markAsNull="markAsNull" :variant="variant" />
        </div> -->
        <vs-divider class="my-4">
          <h4 class="text-lg text-bold text-black-light">Agregar ODC</h4>
        </vs-divider>

        <div class="w-full">
          <div class="flex gap-4 items-end">
            <div>
              <input 
                type="file" 
                :name="`addOdcInput-${localData.campaignId}-${localData.companyId}`" 
                :id="`addOdcInput-${localData.campaignId}-${localData.companyId}`" 
                class="hidden"
                @change="handleFileUpload"
              >
              <span class="text-xs text-black-light ml-1">Seleccionar archivo</span>
              <vs-button 
                color="primary" 
                type="border" 
                icon="note_add"
                title="Subir ODC"
                class="overflow-hidden"
                @click="openFileInput"
              >
                <span class="whitespace-no-wrap w-full">Archivo</span>
              </vs-button>
            </div>
            <vs-select 
              label="Mes"
              v-model="addOdcData.month"
              placeholder="Selecciona el mes"
            >
              <vs-select-item
                v-for="month in months"
                :key="month.value"
                :value="month.value"
                :text="month.label"
              />
              <span slot="no-options">Nada que mostrar.</span>
            </vs-select>
            <vs-input 
              label="Año"
              type="number"
              :placeholder="new Date().getFullYear()"
              v-model="addOdcData.year"
            />
            <vs-input 
              label="Monto total"
              type="number"
              placeholder="1000"
              v-model="addOdcData.total"
            />
          </div>
          <div v-if="addOdcData.file">
            <span class="text-xs text-black-light ml-1">Archivo seleccionado: {{ addOdcData.file.name }}</span>
          </div>
          <div class="flex justify-end mt-6 w-full">

            <vs-button 
              color="primary" 
              type="border" 
              title="Subir ODC" 
              icon="backup" 
              @click="handleAddOdc"
            >
              Subir ODC
            </vs-button>
          </div>
        </div>
      </vs-popup>
    </div>
</template>

<style>
  #show-docs-popup .vs-popup{
    width: 80%;
  }

  
</style>

<script>
import { monthFormatter } from '../../../utils/strings';
import FilesTable from './FilesTable.vue';
import appConfig from '@/../appConfig.js'

export default {
    name: "show-docs-popup",
    components: {
        FilesTable
    },
    props: {
        poModalActive: {
            type: Boolean,
            default: false
        },
        poModalData: {
            type: Object,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        variant: {
          type: String,
          default: 'billing'
        }
    },
    data () {
        return {
            localData: {campaignId: null, companyId: null, purchaseOrder: []},
            monthFormatter,
            addOdcData: {
              file: null,
              month: null,
              year: null,
              total: null
            },
            months: [
              {value: 0, label: 'Provisoria'},
              {value: 1, label: 'Enero'},
              {value: 2, label: 'Febrero'},
              {value: 3, label: 'Marzo'},
              {value: 4, label: 'Abril'},
              {value: 5, label: 'Mayo'},
              {value: 6, label: 'Junio'},
              {value: 7, label: 'Julio'},
              {value: 8, label: 'Agosto'},
              {value: 9, label: 'Septiembre'},
              {value: 10, label: 'Octubre'},
              {value: 11, label: 'Noviembre'},
              {value: 12, label: 'Diciembre'},
            ]
        }
    },
    watch: {
        poModalData(newVal) {
            this.localData = newVal;
        },
        localData: {
          handler(newVal) {
            // Send data to api and update table data
            console.log(newVal);
          },
          deep: true
        }
    },
    methods: {
        handleClose() {
            this.addOdcData = {
              file: null,
              month: null,
              year: null,
              total: null
            }
            this.$emit('update:poModalActive', false); // Notifica al componente padre
        },
        openFileInput() {
            document.getElementById(`addOdcInput-${this.localData.campaignId}-${this.localData.companyId}`).click();
        },
        handleFileUpload(event) {
          const file = event.target.files[0];
          if (!file) return;
          this.addOdcData.file = file;
        },
        handleAddOdc() {
          const {campaignId, companyId} = this.localData;

          if(!this.addOdcData.file || this.addOdcData.month === null || !this.addOdcData.year || !this.addOdcData.total) return;

            if(!campaignId || !companyId) {
              this.$vs.loading.close();
              this.$vs.notify({
                  title: 'Error',
                  text: 'No se pudo subir el archivo',
                  color: 'danger'
              });
              return;
            }

            if (this.addOdcData.file.type !== 'application/pdf' || this.addOdcData.file.size > 2048 * 1000) {
                this.$vs.notify({
                    title: 'Error',
                    text: 'Solo se permiten archivos PDF menores a 2MB',
                    color: 'danger'
                });
                event.target.value = ''; // Reset input
                return;
            }
            // Show loading notification
            this.$vs.loading({
                text: 'Subiendo archivo...',
            });

            // get campaigns and companies
            const formData = new FormData();
            formData.append('file', this.addOdcData.file);
            formData.append('year', this.addOdcData.year);
            formData.append('month', this.addOdcData.month);
            formData.append('total', this.addOdcData.total); // get total

            this.$http.post(`${appConfig.apiUrl}/campaigns/${campaignId}/companies/${companyId}/purchase-orders/upload`, formData)
            .then((response) => {
              // Notify user and add new file to local data
              this.successFileUpload(this.addOdcData.file, response);
            })
            .catch((error) => {
                console.error(error);
                this.$vs.loading.close();
                this.$vs.notify({
                    title: 'Error',
                    text: error.message,
                    color: 'danger'
                });
                event.target.value = ''; // Reset input
                return;
            })
            .finally(() => {
                this.$vs.loading.close();
            });
        },
        successFileUpload(file, response) {
          const {campaignId, companyId} = this.localData;

          const fileName = file.name;
          // Notify user
          this.$vs.notify({
            title: 'Archivo subido correctamente',
            text: `Se ha subido el archivo ${fileName} correctamente :)`,
            color: 'success'
          });

          const {data} = response.data;

          // Add new file to local data
          this.localData.purchaseOrder.push({
            id: data.id,
            file: data.file,
            created_at: data.created_at.date,
            year: data.year,
            month: data.month,
            total_amount: data.total_amount
          });

          this.updateRowOdcData(campaignId, companyId, this.localData.purchaseOrder);
        },
        successFileUpdate(file, response) {
          const {campaignId, companyId} = this.localData;

          const fileName = file.name;
          // Notify user
          this.$vs.notify({
            title: 'Archivo actualizado correctamente',
            text: `Se ha reemplazado el archivo ${fileName} correctamente :)`,
            color: 'success'
          });

          const {data} = response.data;


          // Update local data
          const newData = this.localData.purchaseOrder.map(purchaseOrder => {
            if(purchaseOrder.id === data.id) {
              return {
                id: data.id,
                file: data.file,
                created_at: data.created_at,
                year: data.year,
                month: data.month,
                total_amount: data.total_amount
              }
            }
            return purchaseOrder;
          });
          this.localData.purchaseOrder = newData;
          this.updateRowOdcData(campaignId, companyId, newData);
        },
        updateRowOdcData(campaignId, companyId, newPurchaseOrder) {

          this.$emit('updatePurchaseOrders', {campaignId, companyId, newPurchaseOrder});
        }
        // markAsNull(id) {
        //     const item = this.localData.find(item => item.id === id);
        //     const isNull = item.null;

        //     this.localData.find(item => item.id === id).null = !isNull;
        // }

    }
}


</script>