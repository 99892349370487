<template>
    <div class="companies-select">
        <p>Clientes:</p>
        <v-select
            id="type"
            label="text"
            autocomplete
            :options="companies"
            :clearable="false"
            multiple
            v-model="companiesSelected"
            @input="setCompanies"
            :disabled="loading"
        >
            <span slot="no-options">Nada que mostrar.</span>
        </v-select>
    </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
    components: {
        vSelect
    },
    props: {
        companies: {
            type: Array,
            default: () => []
        },
    },
    data () {
        return {
            //default value
            companiesSelected: [{ value: 'all', text: 'Todos' }],
            loading: false,
        }
    },
    methods: {
        clearCompanies () {
            this.companiesSelected = [{ value: 'all', text: 'Todos' }]
            this.$emit('value', [])
        },
        setCompanies () {
            this.existOptionAll()
            this.$emit('value', this.companiesSelected)
        },
        existOptionAll () {
            const positionOfAll = this.companiesSelected.map(company => company.value).indexOf('all')
            if (positionOfAll === 0) this.companiesSelected.splice(positionOfAll, 1)
            if ((positionOfAll > 0) || (this.companiesSelected.length === 0)) this.companiesSelected = [{value : 'all', text : 'Todos'}]
        }
    },
}
</script>