var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rate-input w-full" },
    [
      _c("p", [_vm._v("Tasa de cambio")]),
      _c("vs-input", {
        attrs: { type: "number", step: "0.1", placeholder: "0.00" },
        model: {
          value: _vm.rate,
          callback: function ($$v) {
            _vm.rate = $$v
          },
          expression: "rate",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }