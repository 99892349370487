// const cellStyles = {
//   billingDate: {backgroundColor: '#ab85ad', color: '#fff'},
//   amountReceivableWithoutTax: {backgroundColor: '#eebcbc', color: '#545454'},
//   amountReceivableWithTax: {backgroundColor: '#ef9a9a', color: '#fff'}
// }

import { programmaticProviderStatusMapping, simplePaymentStatusMapping } from "../misc"    
import { currencyFormatter, fromDateGetter, impressionsShowedGetter, impressionsShowedInSalesPeriodGetter, multiMonthGetter, newComplianceGetter, newExternalDspGetter, newHoldingNameGetter, newTaxGetter, paymentDateGetter, paymentDelayGetter, programmaticProviderNameGetter, stringFormatter, toDateGetter, totalPromisedShowsGetter } from "../strings"

const getFilesData = (params) => {
  const {data} = params
  const {company, campaign} = data
  const {id} = campaign
  const {purchase_order} = company

  if(purchase_order){
    return {
      campaign_id: id,
      files: purchase_order.map((fileData, index) => ({
        id: index,
        file: fileData.file,
        created_at: fileData.created_at,
        billed_at: null,
        billed: false,
        payed_at: null,
        payed: false,
        null: false
      }))
    }
  }

  return {
    campaign_id: id,
    files: []
  }
}

export const columnDefs = ({openPoModal})=> ([
    {
      headerName: 'Campaña',
      headerClass: 'column-group-1',
      children: [
        {
          headerName: 'ID',
          field: 'campaign.id',
          filter: false,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true,
          pinned: 'left',
          lockPinned: true,
          width: 150
        },
        {
          headerName: 'Nombre campaña',
          field: 'campaign.name',
          filter: false,
          width: 300,
          wrapText: true
        },
        {
            headerName: 'Multimes',
            valueGetter: multiMonthGetter,
            filter: false,
            width: 200,
            wrapText: true
        },
        {
          headerName: 'Fecha de Inicio',
          valueGetter: fromDateGetter,
          filter: false,
          width: 180
        },
        {
          headerName: 'Fecha de Fin',
          valueGetter: toDateGetter,
          filter: false,
          width: 150
        },
      ]
    },
    {
      headerName: 'Pantalla',
      headerClass: 'column-group-4',
      children: [
        {
          headerName: 'ID Empresa',
          field: 'company.id',
          filter: false,
          width: 150
        },
        {
          headerName: 'Empresa Pantallero',
          filter: 'agTextColumnFilter',
          field: 'company.name',
          width: 200
        },
        {
          headerName: 'Holding',
          valueGetter: newHoldingNameGetter,
          filter: false,
          width: 200
        },
        {
          headerName: 'Proveedor Programático',
          valueGetter: programmaticProviderNameGetter,
          refData: programmaticProviderStatusMapping(),
          filter: false,
          width: 200
        },
        {
          headerName: 'País',
          field: 'company.country',
          filter: false,
          width: 150
        }
      ]
    },
    {
      headerName: 'Operación',
      headerClass: 'column-group-2',
      children: [
        {
          headerName: 'Divisa',
          field: 'payment.currency_to_pay',
          width: 200,
          filter: false
        },
        {
            headerName: 'Total consumido',
            field: 'payment.amount_consumed',
            valueFormatter: currencyFormatter,
            filter: false,
            width: 350,
            colId: 'amount_consumed',
        },
        {
            headerName: 'Total presupuestado',
            field: 'payment.amount_budgeted',
            valueFormatter: currencyFormatter,
            filter: false,
            width: 350
        },
        //Agregar "Estado de facturación"
        {
          headerName: 'Pagado',
          valueGetter: getFilesData,
          cellRenderer: 'billingAmountRenderer',
          cellRendererParams: {
            variant: 'payed'
          },
          filter: false,
          width: 120,
          cellStyle: {paddingLeft: '5px', paddingRight: '5px'},
          pinned: 'left',
          refData: simplePaymentStatusMapping()
        },
        {
          headerName: 'Cobrado',
          valueGetter: getFilesData,
          cellRenderer: 'billingAmountRenderer',
          cellRendererParams: {
            variant: 'charged'
          },
          filter: false,
          width: 120,
          cellStyle: {paddingLeft: '5px', paddingRight: '5px'},
          pinned: 'left',
          refData: simplePaymentStatusMapping()
        },

        {
            headerName: 'Tax',
            valueGetter: newTaxGetter,
            width: 150
        },
        {
            headerName: 'Tipo de venta',
            valueGetter: newExternalDspGetter,
            filter: false,
            width: 200,
            wrapText: true
        },
        {
          headerName: 'Fecha de pago',
          valueGetter: paymentDateGetter,
          filter: false,
          width: 150
        },
        {
          headerName: 'Días de atraso de pago',
          valueGetter: paymentDelayGetter,
          filter: false,
          width: 150
        },
        {
            headerName: 'Documentación',
            // field: 'company.purchase_order',
            pinned: 'left',
            cellStyle: {textAlign: 'center'},            
            filter: false,
            cellRenderer: (params) => {
                const {data} = params
                const {company, campaign} = data
                const {purchase_order, id: companyId} = company
                const {id: campaignId} = campaign
                
                const button = document.createElement('a')
                button.innerText = 'Ver'
                button.style.cursor = 'pointer'
                button.addEventListener('click', (event) => {
                  event.stopPropagation()
                  const fileData = purchase_order ? purchase_order.map((fileData) => fileData) : []
                  
                  openPoModal({campaignId, companyId}, fileData)
                })
                return button
            }

        }
      ]
    },
    {
      headerName: 'Datos de facturación',
      headerClass: 'column-group-3',
      children: [
        {
          headerName: 'Cotización del día',
          field: 'payment.rate',
          filter: false,
          width: 150
        },
        {
          headerName: 'Pago a pantalleros en rango S/I',
          field: 'payment.paid_amount_currency_to_pay',
          filter: false,
          width: 150
        },
        {
          headerName: 'Impresiones prometidas',
          valueGetter: totalPromisedShowsGetter,
          filter: false,
          width: 300
        },
        {
          headerName: 'Impresiones cumplidas',
          valueGetter: impressionsShowedGetter,
          filter: false,
          width: 300
        },
        {
          headerName: 'Impresiones cumplidas en rango',
          valueGetter: impressionsShowedInSalesPeriodGetter,
          filter: false,
          width: 300
        },
        {
          headerName: 'Cumplimiento',
          valueGetter: newComplianceGetter,
          filter: false,
          width: 200
        },
        {
          headerName: 'Moneda (origen)',
          field: 'payment.rates.from.currency',
          filter: false,
          valueFormatter: stringFormatter,
          width: 200
        },
        {
          headerName: 'Tasa de cambio (origen)',
          // valueGetter: newRateFromGetter,
          field: 'payment.rates.from.rate',
          valueFormatter: currencyFormatter,
          filter: false,
          width: 270
        },
        {
          headerName: 'Moneda (destino)',
          field: 'payment.rates.to.currency',
          filter: false,
          valueFormatter: stringFormatter,
          width: 200
        },
        {
          headerName: 'Tasa de cambio (destino)',
          field: 'payment.rates.to.rate',
          valueFormatter: currencyFormatter,
          filter: false,
          width: 270
        }
      ]
    }
  ])