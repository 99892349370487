var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "multi-month-switch" },
    [
      _c("label", [_vm._v("Multimes")]),
      _c(
        "vs-switch",
        {
          attrs: { "vs-icon-on": "check", color: "success" },
          model: {
            value: _vm.isCampaignLongerThanOneMonth,
            callback: function ($$v) {
              _vm.isCampaignLongerThanOneMonth = $$v
            },
            expression: "isCampaignLongerThanOneMonth",
          },
        },
        [
          _c("span", { attrs: { slot: "on" }, slot: "on" }, [_vm._v("Si")]),
          _c("span", { attrs: { slot: "off" }, slot: "off" }, [_vm._v("No")]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }