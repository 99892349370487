<template>
  <vx-card>
    <screens-filter
      v-on:value="filters = $event"
      @searchData="updateScreens"
      :loadingScreens="loadingScreens"
    ></screens-filter>
    <screens-table
      :screens="screens"
      :loadingScreens="loadingScreens"
      @updateData="updateScreens"
    ></screens-table>
  </vx-card>
</template>
<script>
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import appConfig from '@/../appConfig.js'
import ScreensTable from '../components/screens-table/ScreensTable.vue'
import ScreensFilter from '../components/screens-filter/ScreensFilter.vue'
import qs from 'qs'

export default {
  components: {
    ScreensTable,
    ScreensFilter
  },
  data() {
    return {
      screens: [],
      loadingScreens: false,
      filters: null
    }
  },
  watch: {
    filters(_, oldValue) {
      if (!oldValue) return
      this.updateScreens()
    }
  },
  computed: {},
  methods: {
    getScreensData() {
      this.countSelected = 0
      this.loadingScreens = true
      this.$http
        .get(`${appConfig.apiUrl}/panel/displays`, {
          params: this.parseFilter(),
          paramsSerializer: (params) => {
            return qs.stringify(params, {
              arrayFormat: 'brackets'
            })
          }
        })
        .then((response) => {
          this.screens = response.data
        })
        .catch((error) => {
          console.log(error)
        })
        .then(() => {
          this.loadingScreens = false
        })
    },
    parseFilter() {
      return {
        countries: this.setAtributeFilter('countries'),
        holdings: this.setAtributeFilter('holdings'),
        companies: this.setAtributeFilter('companies')
      }
    },
    setAtributeFilter(name) {
      if (this.filters[name].some((elment) => elment.value === 'all')) return []
      if (name === 'countries')
        return this.filters[name].map((element) => element.alpha_2_code)
      return this.filters[name].map((element) => element.value)
    },
    updateScreens() {
      this.getScreensData()
    }
  },
  mounted() {
    this.getScreensData()
  }
}
</script>
