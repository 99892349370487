<template>
    <div>
      <SSRAgTable
        v-on="$listeners"
        :frameworkComponents="frameworkComponents"
        :columns="columnDefs"
        :data="rowData"
        :totalRows="totalRows"
        :totalPages="totalPages"
        :isLoading="isLoading"
        @fetchData="getGridData"
        @updateSelectedItems="onSelectedItems"
      >
      </SSRAgTable>
  
  
      <!-- Detail popup -->
      <show-content-display-detail
        :isModalActive.sync="isDetailModalActive"
        :modalData="detailModalData"
      />
    </div>
  </template>
    
    <style>
    /* Add !important to display: none added by v-show directive */
    *[style*="display: none"] {
      display: none !important;
    }
    </style>
    
    <script>
    import { LicenseManager } from 'ag-grid-enterprise'
    LicenseManager.setLicenseKey('CompanyName=Servoy B.V.,LicensedApplication=Servoy,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=7,LicensedProductionInstancesCount=200,AssetReference=AG-010463,ExpiryDate=11_October_2021_[v2]_MTYzMzkwNjgwMDAwMA==4c6752fe4cb2066ab1f0e9c572bc7491')
    import SSRAgTable from '../components/ag-table/SSRAgTable.vue'
    import appConfig from '@/../appConfig.js'
    import PayedRenderer from './CampaignsPaymentsRenderer.vue'
    import ShowContentDisplayDetail from '../components/popups/ShowContentDisplayDetail.vue'
    import { columnDefs } from '../utils/tables/closings'
    
    export default {
      name: 'closings-table',
      components: {
        SSRAgTable,
        ShowContentDisplayDetail
      },
      props: {
        filters: {
          type: Object,
          required: true
        },
        filterType: {
          type: Object,
          required: true
        }
      },
      data () {
        return {
          // closings grid options
          currentPage: 1,
          totalRows: 0,
          totalPages: 1,
          columnDefs: columnDefs({
            openDetailModal: this.openDetailModal.bind(this) 
          }),
          rowData: [],
          frameworkComponents: null,
          //Items selected
          selectedRows: null,
          countSelected: 0,
          //loading flag
          isLoading: false,
          //Documentation
          isDetailModalActive: false,
          detailModalData: null,
          //change state
          popupActiveChangeState: false,
          popupActiveChangeStateTitle: 'Cambiar estado',
        }
      },
      watch: {},
      computed: {},
      methods: {
        getGridData ({ page = 1, pageSize = 20 }) {
          this.isLoading = true;
          this.countSelected = 0
          this.$http.get(`${appConfig.apiUrl}/panel/campaigns/content-display`, {
            params: {
              filters: this.filters,
              page: page,
              page_size: pageSize
            }
          })
          .then((response) => {
            const {total, data, last_page, current_page} = response.data
    
            this.currentPage = current_page
            this.totalRows = total
            this.rowData = data
            this.totalPages = last_page
            // const {rowData} = response.data
  
            // this.currentPage = 1
            // this.totalRows = 100
            // this.rowData = rowData
            // this.totalPages = 1
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.isLoading = false
          })
          // .then(() => {
          //   this.isLoading = false
          // })
        },
        // Method to open the purchase order modal
        openDetailModal (data) {
          this.isDetailModalActive = true
          this.detailModalData = data
        },
        closeDetailModal () {
          this.isDetailModalActive = false
          this.detailModalData = null
        },
        onSelectedItems (selectedItems) {
          this.selectedRows = selectedItems
          this.countSelected = selectedItems.length
        }
      },
      beforeMount () {
        this.frameworkComponents = {
          payedRenderer: PayedRenderer
        }
      },
      mounted () {}
    }
    </script>