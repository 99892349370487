<template>
  <div class="custom-tooltip vs-tooltip vs-tooltip-bottom">
    <p v-for="(value, key) in values" :key="key">{{ value }}</p>
  </div>
</template>
<script>
import Vue from 'vue'
import '@/assets/css/agTooltip.css'

export default Vue.extend({
  data() {
    return {
      values: []
    }
  },
  beforeMount() {
    this.values = Object.values(this.params.value)
  }
})
</script>
