var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "centerx" },
    [
      _c(
        "vs-popup",
        {
          staticClass: "modal-docs",
          attrs: {
            title: _vm.title,
            active: _vm.poModalActive,
            id: "show-docs-popup",
          },
          on: {
            "update:active": function ($event) {
              _vm.poModalActive = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c("files-table", {
            attrs: { tableData: _vm.localData, variant: _vm.variant },
            on: { successFileUpdate: _vm.successFileUpdate },
          }),
          _c("vs-divider", { staticClass: "my-4" }, [
            _c("h4", { staticClass: "text-lg text-bold text-black-light" }, [
              _vm._v("Agregar ODC"),
            ]),
          ]),
          _c("div", { staticClass: "w-full" }, [
            _c(
              "div",
              { staticClass: "flex gap-4 items-end" },
              [
                _c(
                  "div",
                  [
                    _c("input", {
                      staticClass: "hidden",
                      attrs: {
                        type: "file",
                        name: `addOdcInput-${_vm.localData.campaignId}-${_vm.localData.companyId}`,
                        id: `addOdcInput-${_vm.localData.campaignId}-${_vm.localData.companyId}`,
                      },
                      on: { change: _vm.handleFileUpload },
                    }),
                    _c(
                      "span",
                      { staticClass: "text-xs text-black-light ml-1" },
                      [_vm._v("Seleccionar archivo")]
                    ),
                    _c(
                      "vs-button",
                      {
                        staticClass: "overflow-hidden",
                        attrs: {
                          color: "primary",
                          type: "border",
                          icon: "note_add",
                          title: "Subir ODC",
                        },
                        on: { click: _vm.openFileInput },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "whitespace-no-wrap w-full" },
                          [_vm._v("Archivo")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "vs-select",
                  {
                    attrs: { label: "Mes", placeholder: "Selecciona el mes" },
                    model: {
                      value: _vm.addOdcData.month,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOdcData, "month", $$v)
                      },
                      expression: "addOdcData.month",
                    },
                  },
                  [
                    _vm._l(_vm.months, function (month) {
                      return _c("vs-select-item", {
                        key: month.value,
                        attrs: { value: month.value, text: month.label },
                      })
                    }),
                    _c(
                      "span",
                      { attrs: { slot: "no-options" }, slot: "no-options" },
                      [_vm._v("Nada que mostrar.")]
                    ),
                  ],
                  2
                ),
                _c("vs-input", {
                  attrs: {
                    label: "Año",
                    type: "number",
                    placeholder: new Date().getFullYear(),
                  },
                  model: {
                    value: _vm.addOdcData.year,
                    callback: function ($$v) {
                      _vm.$set(_vm.addOdcData, "year", $$v)
                    },
                    expression: "addOdcData.year",
                  },
                }),
                _c("vs-input", {
                  attrs: {
                    label: "Monto total",
                    type: "number",
                    placeholder: "1000",
                  },
                  model: {
                    value: _vm.addOdcData.total,
                    callback: function ($$v) {
                      _vm.$set(_vm.addOdcData, "total", $$v)
                    },
                    expression: "addOdcData.total",
                  },
                }),
              ],
              1
            ),
            _vm.addOdcData.file
              ? _c("div", [
                  _c("span", { staticClass: "text-xs text-black-light ml-1" }, [
                    _vm._v(
                      "Archivo seleccionado: " +
                        _vm._s(_vm.addOdcData.file.name)
                    ),
                  ]),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "flex justify-end mt-6 w-full" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: {
                      color: "primary",
                      type: "border",
                      title: "Subir ODC",
                      icon: "backup",
                    },
                    on: { click: _vm.handleAddOdc },
                  },
                  [_vm._v("\n          Subir ODC\n        ")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }