<template>
    <div class="multi-month-switch">
        <label>Multimes</label>
        <vs-switch
            vs-icon-on="check"
            color="success"
            v-model="isCampaignLongerThanOneMonth"
        >
            <span slot="on">Si</span>
            <span slot="off">No</span>
        </vs-switch>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                isCampaignLongerThanOneMonth: false
            }
        },
        methods: {
            setMultimonth(value) {
                this.$emit('value', value)
            }
        },
        watch: {
            isCampaignLongerThanOneMonth(value) {
                this.setMultimonth(value)
            }
        }
    }
</script>
