var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ag-table",
    _vm._g(
      {
        attrs: {
          data: _vm.screens,
          loadingData: _vm.loadingScreens,
          columns: _vm.columns,
          frameworkComponents: _vm.frameworkComponents,
        },
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }