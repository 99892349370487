<template>
  <vx-card class="mb-8">
    <vs-row vs-spacer="2">
      <vs-col vs-w="2" vs-offset=".1">
        <country-select v-on:value="filters.countries = $event" />
      </vs-col>
      <vs-col vs-w="2" vs-offset=".1">
        <div class="holding-select">
          <p>Seleccionar Holding:</p>
          <v-select
            id="type"
            label="text"
            :options="holdings"
            :clearable="false"
            multiple
            v-model="filters.holdings"
            @input="getCompanies"
           :disabled="loadingScreens"
          >
            <span slot="no-options">Nada que mostrar.</span>
          </v-select>
        </div>
      </vs-col>
      <vs-col vs-w="2" vs-offset=".1">
        <div
          class="company-select"
        >
          <p>Seleccionar Medio:</p>
          <v-select
            id="type"
            label="text"
            :options="companies"
            :clearable="false"
            multiple
            v-model="filters.companies"
            @input="() => existOptionAll('companies')"
           :disabled="loadingScreens"
          >
            <span slot="no-options">Nada que mostrar.</span>
          </v-select>
        </div>
      </vs-col>
    </vs-row>
    <vs-row class="mt-4" vs-type="flex" vs-justify="flex-end">
      <vs-button
        size="large"
        @click="$emit('searchData')"
        class="mr-3 mb-2"
        :disabled="loadingScreens"
        >Buscar</vs-button
      >
    </vs-row>
  </vx-card>
</template>
<script>
import CountrySelect from '../country-select/CountrySelect.vue'
import vSelect from 'vue-select'
import appConfig from '../../../appConfig'
import { sortAlphabetize } from '@/utils/utils.js'

export default {
  components: {
    CountrySelect,
    vSelect
  },
  props: {
    loadingScreens: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      filters: {
        countries: [{ value: 'all', text: 'Todos' }],
        companies: [{ value: 'all', text: 'Todos' }],
        holdings: [{ value: 'all', text: 'Todos' }]
      },
      holdings: [],
      companies: []
    }
  },
  watch: {},
  computed: {},
  methods: {
    getHoldingsData() {
      this.loading = true
      this.$http
        .get(`${appConfig.apiUrl}/panel/holdings`)
        .then((response) => {
          this.holdings = response.data.map((holding) => ({
            ...holding,
            text: holding.name,
            value: holding.id
          }))
          this.holdings = sortAlphabetize(this.holdings, 'text')
          this.holdings.unshift({ value: 'all', text: 'Todos' })

          if (this.countriesSelect.length > 0) {
            this.filters.holdings = []
            this.$emit('searchData')
          }

          this.loading = false
          this.getCompanies()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getCompanies() {
      this.existOptionAll('holdings')
      this.clearDataCompanies()
      //Show all media options if holding selected is "all" 
      if (this.filters.holdings.every((holding) => holding.value === 'all'))
        return this.generateArrCompanies(this.holdings)
      this.generateArrCompanies(this.filters.holdings)
    },
    generateArrCompanies(arrHoldings) {
      arrHoldings.forEach((holding) => {
        holding.companies &&
          holding.companies.forEach((holdCompany) => {
            if (
              this.companies.some((company) => company.value === holdCompany.id)
            )
              return
            this.companies.push({
              ...holdCompany,
              text: holdCompany.name,
              value: holdCompany.id
            })
          })
      })
      this.companies = sortAlphabetize(this.companies, 'text')
      this.companies.unshift({ value: 'all', text: 'Todos' })
    },
    clearDataCompanies() {
      this.companies = []
      //Clears the options when different holding is selected, except option all
      this.filters.companies = [{ value: 'all', text: 'Todos' }]
    },
    //If something other than "all" is selected, it's removed. If select "all" again, everything is deleted.
    existOptionAll(nameData) {
      const positionOfAll = this.filters[nameData]
        .map((countrie) => countrie.value)
        .indexOf('all')
      if (positionOfAll === 0) this.filters[nameData].splice(positionOfAll, 1)
      if (positionOfAll > 0 || this.filters[nameData].length === 0)
        this.filters[nameData] = [{ value: 'all', text: 'Todos' }]
    }
  },
  async beforeMount() {
    this.$emit('value', this.filters)
    this.getHoldingsData()
  }
}
</script>
