var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "custom-tooltip vs-tooltip vs-tooltip-bottom" },
    _vm._l(_vm.values, function (value, key) {
      return _c("p", { key: key }, [_vm._v(_vm._s(value))])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }