<template>
    <div>
      <SSRAgTable
        v-on="$listeners"
        :frameworkComponents="frameworkComponents"
        :columns="columnDefs"
        :data="rowData"
        :totalRows="totalRows"
        :totalPages="totalPages"
        :isLoading="isLoading"
        @fetchData="getGridData"
        @updateSelectedItems="onSelectedItems"
      >
      </SSRAgTable>
  
      <!-- Documentation popup -->
      <show-docs-popup
        :poModalActive.sync="poModalActive"
        :poModalData="poModalData"
        title="Pagos" 
        variant="payment"
        @updatePurchaseOrders="updatePurchaseOrders"
      />
    </div>
  </template>
    
    <style>
    /* Add !important to display: none added by v-show directive */
    *[style*="display: none"] {
      display: none !important;
    }
    </style>
    
    <script>
    import { LicenseManager } from 'ag-grid-enterprise'
    LicenseManager.setLicenseKey('CompanyName=Servoy B.V.,LicensedApplication=Servoy,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=7,LicensedProductionInstancesCount=200,AssetReference=AG-010463,ExpiryDate=11_October_2021_[v2]_MTYzMzkwNjgwMDAwMA==4c6752fe4cb2066ab1f0e9c572bc7491')
    import SSRAgTable from '../components/ag-table/SSRAgTable.vue'
    import appConfig from '@/../appConfig.js'
    import { columnDefs } from '../utils/tables/payments'
    
    import ShowDocsPopup from '../components/popups/show-docs-popup/ShowDocsPupup.vue'
    import BillingAmountRenderer from '../components/table-cells/BillingAmountRenderer.vue'
    
    export default {
      name: 'payments-table',
      components: {
        SSRAgTable,
        ShowDocsPopup
      },
      props: {
        filters: {
          type: Object,
          required: true
        },
      },
      data () {
        return {
          // payments grid options
          currentPage: 1,
          totalRows: 0,
          totalPages: 1,
          columnDefs: columnDefs({
            openPoModal: this.openPoModal.bind(this) 
          }),
          rowData: [],
          frameworkComponents: null,
          //Items selected
          selectedRows: null,
          countSelected: 0,
          //loading flag
          isLoading: false,
          //Documentation
          poModalActive: false,
          poModalData: null,
        }
      },
      watch: {},
      computed: {},
      methods: {
        getGridData ({ page = 1, pageSize = 20, name = "" }) {
          this.isLoading = true;
          this.countSelected = 0
          let {countries, holdings, isCampaignLongerThanOneMonth, providers, rateFromCurrency, rateToCurrency, rate} = this.filters
          
          const countiesArray = countries[0].value === 'all' ? null : countries.map(country => country.name)
          const holdingArray = holdings.length > 0 ? (holdings[0].value === 'all' ? null : holdings.map(holding => holding.value)) : null
          const providersArray = providers.length > 0 ? (providers[0].value === 'all' ? null : providers.map(provider => provider.id)) : null
          const rateFromCurrencySelected = rateFromCurrency ? (rateFromCurrency.value === 'all' ? null : rateFromCurrency.value) : null
          const rateToCurrencySelected = rateToCurrency ? (rateToCurrency.value === 'all' ? null : rateToCurrency.value) : null

          this.$http.get(`${appConfig.apiUrl}/panel/payments`, {
            params: {
              countries: countiesArray,
              holdings: holdingArray,
              is_campaign_longer_than_one_month: Number(isCampaignLongerThanOneMonth),
              programmatic_providers: providersArray,
              page,
              per_page: pageSize,
              campaign_name: name,
              rate_from_currency: rateFromCurrencySelected,
              rate_to_currency: rateToCurrencySelected,
              rate
            }
          })
          .then((response) => {
            const { pagination, data } = response.data
            const { total, last_page, current_page } = pagination

    
            this.currentPage = current_page
            this.totalRows = total
            this.rowData = data
            this.totalPages = last_page
            // const {rowData} = response.data
  
            // this.currentPage = 1
            // this.totalRows = 100
            // this.rowData = rowData
            // this.totalPages = 1
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.isLoading = false
          })
          // .then(() => {
          //   this.isLoading = false
          // })
        },
        updatePurchaseOrders({campaignId, companyId, newPurchaseOrder}) {
          const newData = this.rowData.map(row => {
            if(row.campaign.id === campaignId && row.company.id === companyId) {
              return {
                ...row,
                company: {
                  ...row.company,
                  purchase_order: newPurchaseOrder
                }
              }
            }
            return row;
          });
          this.rowData = newData;
        },
        // Method to open the purchase order modal
        openPoModal ({campaignId, companyId}, purchaseOrder) {
          this.poModalActive = true
          this.poModalData = {campaignId, companyId, purchaseOrder}
        },
        onSelectedItems (selectedItems) {
          this.selectedRows = selectedItems
          this.countSelected = selectedItems.length
        }
      },
  beforeMount() {
    this.frameworkComponents = {
      billingAmountRenderer: BillingAmountRenderer,
    }
  },
  mounted() { }
}
</script>