var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { staticClass: "mb-8" },
    [
      _c(
        "vs-row",
        { attrs: { "vs-spacer": "2" } },
        [
          _c(
            "vs-col",
            { attrs: { "vs-w": "2", "vs-offset": ".1" } },
            [
              _c("country-select", {
                on: {
                  value: function ($event) {
                    _vm.filters.countries = $event
                  },
                },
              }),
            ],
            1
          ),
          _c("vs-col", { attrs: { "vs-w": "2", "vs-offset": ".1" } }, [
            _c(
              "div",
              { staticClass: "holding-select" },
              [
                _c("p", [_vm._v("Seleccionar Holding:")]),
                _c(
                  "v-select",
                  {
                    attrs: {
                      id: "type",
                      label: "text",
                      options: _vm.holdings,
                      clearable: false,
                      multiple: "",
                      disabled: _vm.loadingScreens,
                    },
                    on: { input: _vm.getCompanies },
                    model: {
                      value: _vm.filters.holdings,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "holdings", $$v)
                      },
                      expression: "filters.holdings",
                    },
                  },
                  [
                    _c(
                      "span",
                      { attrs: { slot: "no-options" }, slot: "no-options" },
                      [_vm._v("Nada que mostrar.")]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("vs-col", { attrs: { "vs-w": "2", "vs-offset": ".1" } }, [
            _c(
              "div",
              { staticClass: "company-select" },
              [
                _c("p", [_vm._v("Seleccionar Medio:")]),
                _c(
                  "v-select",
                  {
                    attrs: {
                      id: "type",
                      label: "text",
                      options: _vm.companies,
                      clearable: false,
                      multiple: "",
                      disabled: _vm.loadingScreens,
                    },
                    on: { input: () => _vm.existOptionAll("companies") },
                    model: {
                      value: _vm.filters.companies,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "companies", $$v)
                      },
                      expression: "filters.companies",
                    },
                  },
                  [
                    _c(
                      "span",
                      { attrs: { slot: "no-options" }, slot: "no-options" },
                      [_vm._v("Nada que mostrar.")]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "vs-row",
        {
          staticClass: "mt-4",
          attrs: { "vs-type": "flex", "vs-justify": "flex-end" },
        },
        [
          _c(
            "vs-button",
            {
              staticClass: "mr-3 mb-2",
              attrs: { size: "large", disabled: _vm.loadingScreens },
              on: {
                click: function ($event) {
                  return _vm.$emit("searchData")
                },
              },
            },
            [_vm._v("Buscar")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }