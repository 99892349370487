// const cellStyles = {
//   billingDate: {backgroundColor: '#ab85ad', color: '#fff'},
//   amountReceivableWithoutTax: {backgroundColor: '#eebcbc', color: '#545454'},
//   amountReceivableWithTax: {backgroundColor: '#ef9a9a', color: '#fff'}
// }
import { bussinessModelGetter, currencyCheck, externalDspGetter, finalAmountGetter, finalWithTaxGetter, fromDateGetter, guaranteeGetter, holdingNameGetter, multiMonthGetter, rateFromGetterValue, taxGetter, toDateGetter } from "../strings"


export const columnDefs = ({openDetailModal})=> ([
    {
      headerName: 'Campaña',
      headerClass: 'column-group-1',
      children: [
        {
          headerName: 'ID',
          field: 'campaign_id',
          filter: false,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true,
          pinned: 'left',
          lockPinned: true,
          width: 150
        },
        //Esta columna se va a filtrar
        {
          headerName: 'Nombre campaña',
          field: 'campaign_name',
          filter: false,
          width: 300,
          wrapText: true
        },
        //Esta columna se va a filtrar
        {
            headerName: 'Multimes',
            valueGetter: multiMonthGetter,
            filter: false,
            width: 200,
            wrapText: true
        },
        {
          headerName: 'Fecha de Inicio',
          valueGetter: fromDateGetter,
          filter: false,
          width: 180
        },
        {
          headerName: 'Fecha de Fin',
          valueGetter: toDateGetter,
          filter: false,
          width: 150
        },
      ]
    },
    {
      headerName: 'Pantalla',
      headerClass: 'column-group-4',
      children: [
        //Esta columna se va a filtrar
        {
          headerName: 'ID Empresa',
          field: 'company_id',
          filter: false,
          width: 150
        },
        //Esta columna se va a filtrar
        {
          headerName: 'Empresa Pantallero',
          field: 'company_name',
          filter: false,
          width: 200
        },
        //Esta columna se va a filtrar
        {
          headerName: 'Holding',
          valueGetter: holdingNameGetter,
          filter: false,
          width: 200
        },
        //Esta columna se va a filtrar
        {
          headerName: 'País',
          field: 'display_country',
          filter: false,
          width: 150
        }
      ]
    },
    {
      headerName: 'Operación',
      headerClass: 'column-group-2',
      children: [
        //Esta columna se va a filtrar
        {
          headerName: 'Modelo de negocio',
          valueGetter: bussinessModelGetter,
          pinned: 'left',
          filter: false,
          width: 200,
          wrapText: true
        },
        //Esta columna se va a filtrar
        {
            headerName: 'DSP',
            valueGetter: externalDspGetter,
            filter: false,
            width: 120,
            wrapText: true
        },
        //Esta columna se va a filtrar
        {
          headerName: 'Garantizado',
          valueGetter: guaranteeGetter,
          filter: false,
          width: 120,
          wrapText: true
        },
        //Esta columna se va a filtrar
        {
          headerName: 'Divisa',
          field: 'currency_to_pay',
          width: 100,
          filter: false
        },
        {
            headerName: 'Consumido',
            field: 'total_price_consumed',
            valueFormatter: currencyCheck,
            filter: false,
            width: 350,
            colId: 'total_price_consumed'
        },
        // cual es el campo que se va a mostrar?
        {
            headerName: 'Estimado',
            field: 'paid_amount',
            valueFormatter: currencyCheck,
            filter: false,
            width: 350,
            colId: 'paid_amount',
        },
        {
            headerName: 'Final',
            valueGetter: finalAmountGetter,
            filter: false,
            width: 350,
            colId: 'final_price',
        },
        {
            headerName: 'Tax',
            valueGetter: taxGetter,
            width: 150
        },
        //BACKEND REQUIRED
        {
            headerName: 'Final + Impuestos',
            valueGetter: finalWithTaxGetter,
            width: 150
        },
        //BACKEND REQUIRED
        {
            headerName: 'Monto OC',
            valueGetter: ()=> '-',
            width: 150
        },
        {
            headerName: 'Consumo externo',
            valueGetter: ()=> '-',
            width: 150
        },
        //BACKEND REQUIRED
        {
          headerName: 'Coincide',
          valueGetter: ()=> '-',
          width: 150
        },
        {
          headerName: 'ODC Cerrada',
          valueGetter: ()=> '-',
          width: 150
        },
        {
          headerName: 'Enviada vía mail',
          valueGetter: ()=> '-',
          width: 150
        },
        {
          headerName: 'Cierre',
          valueGetter: (params)=> {
            const {data} = params
            const {purchase_order} = data
            return {
              purchase_order_client: JSON.parse(purchase_order),
              purchase_order_media_company_provisory: null,
              purchase_order_media_company_final: null,
              total_medios_company_odc_amount: null,
              campaign_price: null,
              total_amount: null,
              null: false
            }
          },
          cellRenderer: (params) => {
            const {value} = params
            const button = document.createElement('a')
            button.innerText = 'Abrir detalle'
            button.style.cursor = 'pointer'
            button.addEventListener('click', (event) => {
              event.stopPropagation()
              openDetailModal(value)
            })
            return button
          },
          width: 150
        },
        {
          headerName: 'Tasa',
          valueGetter: rateFromGetterValue,
          width: 150
        },

        // {
        //     headerName: 'Documentos',
        //     field: 'purchase_order',
        //     filter: false,
        //     cellRenderer: (params) => {
        //         if (params.value) {
        //           const button = document.createElement('a')
        //           button.innerText = 'Ver'
        //           button.addEventListener('click', () => openPoModal(params.value))
        //           return button
        //         } else {
        //           return '-'
        //         }
        //     }
        // }
      ]
    },
  ])