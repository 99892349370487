<template>
    <div class="rate-input w-full">
        <p>Tasa de cambio</p>
        <vs-input type="number" v-model="rate" step="0.1" placeholder="0.00"/>
    </div>
</template>

<script>
export default {
    data() {
        return {
            rate: ""
        }
    },
    watch: {
        rate(newVal) {
            this.$emit('value', newVal)
        }
    }
}
</script>