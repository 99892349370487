<template>
    <div class="programmatic-provider-select">
        <p>Proveedor programático:</p>
        <v-select
            id="type"
            label="text"
            :options="providers"
            :clearable="false"
            multiple
            v-model="providersSelected"
            @input="setProviders"
            :disabled="loading"
        >
            <span slot="no-options">Nada que mostrar.</span>
        </v-select>
    </div>
  </template>
  
  <script>
    import vSelect from 'vue-select'
    
  export default {
    components: {
        vSelect
    },
    data () {
        return {
            providers: [
                { value: 'all', text: 'Todos', id: 0 },
                { value: 'latinad', text: 'LatinAD', id: 1 },
                { value: 'place_exchange', text: 'Place Exchange', id: 2 },
                { value: 'magnite', text: 'Magnite', id: 3 },
                { value: 'hivestack', text: 'Hivestack', id: 4 },
                { value: 'beeyond', text: 'Beyeond', id: 5 },
                { value: 'outcon', text: 'Outcon', id: 6 },
                { value: 'taggify', text: 'Taggify', id: 7 },
                { value: 'moving_walls', text: 'Moving Walls', id: 8 },
                { value: 'vistar_media', text: 'Vistar Media', id: 9 },
                { value: 'prodooh', text: 'Prodooh', id: 10 }
            ],
            providersSelected: [{ value: 'all', text: 'Todos', id: 0 }],
            loading: false
        }
    },
    methods: {
        setProviders () {
            this.existOptionAll()
            this.$emit('value', this.providersSelected)
        },
        existOptionAll () {
            const positionOfAll = this.providersSelected.map(provider => provider.value).indexOf('all')
            if (positionOfAll === 0) this.providersSelected.splice(positionOfAll, 1)
            if ((positionOfAll > 0) || (this.providersSelected.length === 0)) this.providersSelected = [{value : 'all', text : 'Todos', id: 0}]
        }
    },
    mounted() {
        this.setProviders()
    }
}
</script>
