<template>
  <ag-table
    :data="screens"
    :loadingData="loadingScreens"
    :columns="columns"
    v-on="$listeners"
    :frameworkComponents="frameworkComponents"
  ></ag-table>
</template>
<script>
import AgTable from '../ag-table/AgTable.vue'
import AgTooltip from '../ag-tooltip/AgTooltip.vue'

export default {
  components: {
    AgTable
  },
  props: {
    screens: {
      type: [],
      required: true
    },
    loadingScreens: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      columns: [
        {
          headerName: 'Medio',
          headerClass: 'column-group-1',
          children: [
            {
              headerName: 'ID',
              field: 'company_id',
              width: 150,
              filter: true,
              sort: 'desc'
            },
            {
              headerName: 'Nombre',
              field: 'company.name',
              filter: true,
              width: 200,
              maxWidth: 200,
              wrapText: true
            }
          ]
        },
        {
          headerName: 'Pantallas',
          headerClass: 'column-group-2',
          children: [
            {
              headerName: 'ID',
              field: 'id',
              width: 150,
              filter: true,
              sort: 'desc'
            },
            {
              headerName: 'Nombre',
              field: 'name',
              filter: true,
              width: 200,
              maxWidth: 200,
              wrapText: true
            },
            {
              headerName: 'País',
              field: 'country',
              filter: true,
              width: 200
            },
            {
              headerName: 'Resolución',
              filter: true,
              width: 200,
              maxWidth: 200,
              wrapText: true,
              valueGetter: (params) => `${params.data.resolution_width}x${params.data.resolution_height}`
            },
            {
              headerName: 'Duración del spot',
              filter: true,
              width: 200,
              maxWidth: 200,
              wrapText: true,
              valueGetter: (params) => `${params.data.slot_length / 1000} seg`
            },
            {
              headerName: 'Última Conexión',
              field: 'last_connection_display_local_time',
              width: 200,
              filter: true
            },
            {
              headerName: 'Estado de Pantalla',
              valueGetter: this.getStatusScreens,
              filter: true,
              width: 190,
              tooltipComponent: 'agTooltip',
              tooltipValueGetter: this.generateTooltipStatus,
              cellClassRules: {
                'green-success ': 'x === "Online"',
                'red-error': 'x === "Offline"',
                'yellow-warning': 'x === "No enlazada"'
              }
            }
          ]
        },
        {
          headerName: 'Venta en el DSP',
          headerClass: 'column-group-3',
          children: [
            {
              headerName: 'Estado Venta Tradicional',
              valueGetter: this.getStatusTraditionalSale,
              filter: true,
              width: 230,
              cellClassRules: {
                'green-success ': 'x === "Publicada"',
                'red-error': 'x === "No Publicada"'
              }
            },
            {
              headerName: 'Divisa',
              field: 'display_currency',
              filter: true,
              width: 120
            },
            {
              headerName: 'Precio Neto Media Owner',
              valueGetter: (params) => parseFloat(params.data.price_per_day * 30).toFixed(2),
              valueFormatter: this.currencyFormatter,
              filter: true,
              width: 250
            },
            {
              headerName: 'Precio Marketplace',
              valueGetter: (params) => parseFloat(this.calculateComission(params, 'commission')).toFixed(2),
              valueFormatter: this.currencyFormatter,
              filter: true,
              width: 200
            },
            {
              headerName: 'Estado Venta Programatica',
              valueGetter: (params) =>
                this.getStatusActive(params.data.smart_campaign_enabled),
              filter: true,
              width: 250,
              cellClassRules: {
                'green-success ': 'x === "Activa"',
                'red-error': 'x === "Inactiva"'
              }
            },
            {
              headerName: 'Precio Neto CPM',
              valueGetter: (params) => parseFloat(params.data.smart_campaign_cpm || 0).toFixed(2),
              valueFormatter: this.currencyFormatter,
              filter: true,
              width: 180
            },
            {
              headerName: 'Precio CPM Marketplace',
              valueGetter: (params) => parseFloat(this.calculateComission(params, 'commission_cpm')).toFixed(2),
              valueFormatter: this.currencyFormatter,
              filter: true,
              width: 240
            }
          ]
        },
        {
          headerName: 'Medición de Audiencia',
          headerClass: 'column-group-4',
          children: [
            {
              headerName: 'Audiencia',
              valueGetter: (params) =>
                this.getStatusActive(params.data.measure_audience),
              filter: true,
              width: 150,
              cellClassRules: {
                'green-success ': 'x === "Activa"',
                'red-error': 'x === "Inactiva"'
              }
            },
            {
              headerName: 'Camara de Audiencia',
              valueGetter: (params) =>
                this.getStatusActive(params.data.measure_camera_audience),
              filter: true,
              width: 210,
              cellClassRules: {
                'green-success ': 'x === "Activa"',
                'red-error': 'x === "Inactiva"'
              }
            },
            {
              headerName: 'Cámara de Certificación',
              valueGetter: (params) =>
                this.getStatusActive(params.data.measure_camera_certification),
              filter: true,
              width: 230,
              cellClassRules: {
                'green-success ': 'x === "Activa"',
                'red-error': 'x === "Inactiva"'
              }
            }
          ]
        },
        {
          headerName: 'Datos',
          headerClass: 'column-group-6',
          children: [
            {
              headerName: 'Alta de Pantalla',
              field: 'created_at',
              valueGetter: (params) =>
                this.$options.filters.dateFormatter(params.data.created_at),
              filter: true,
              width: 180
            },
            {
              headerName: 'Ultima modificación',
              valueGetter: (params) =>
                this.$options.filters.dateFormatter(params.data.updated_at),
              filter: true,
              width: 200
            },
            {
              headerName: 'Usa el gestor',
              field: 'use_cms',
              valueGetter: (params) => {
                if (params.data.use_cms) return 'Si'
                return 'No'
              },
              filter: true,
              width: 150,
              cellRenderer: (params) => {
                if (params.value === 'No')
                  return '<span class="ag-cell ag-icon ag-icon-cross"></span>'
                return '<span class="ag-cell ag-icon ag-icon-tick"></span>'
              }
            }
          ]
        }
      ],
      frameworkComponents: null
    }
  },
  watch: {},
  computed: {},
  methods: {
    generateTooltipStatus(params) {
      const { last_connection_display_local_time } = params.data
      const line1 = 'Ultima conexión'
      const line2 = `${last_connection_display_local_time || 'Sin registrar'}`
      return { line1, line2 }
    },
    getStatusScreens(params) {
      const { status_main_display, paired, is_online } = params.data
      if (status_main_display) return 'Circuito'
      if (paired === 'no') return 'No enlazada'
      if (!is_online) return 'Offline'
      return 'Online'
    },
    getStatusTraditionalSale(params) {
      const { published } = params.data
      if (!published) return 'No Publicada'
      return 'Publicada'
    },
    getStatusActive(atribute) {
      if (!atribute || atribute === 'inactive') return 'Inactiva'
      return 'Activa'
    },
    calculateComission(params, atribute) {
      const { price_per_day, smart_campaign_cpm } = params.data
      const commission =
        params.data[atribute] && parseFloat(params.data[atribute])
      const price =
        atribute === 'commission' ? price_per_day * 30 : smart_campaign_cpm
      return price * (commission / 100 + 1)
    },
    currencyFormatter (params) {
      return this.$options.filters.currencyFormatter(params.value || 0)
    }
  },
  beforeMount() {
    this.frameworkComponents = {
      agTooltip: AgTooltip
    }
  },
  mounted() {}
}
</script>
