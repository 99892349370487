<template>
    <div class="centerx">
      <vs-popup 
        title="Detalle" 
        :active.sync="isModalActive"
        @close="handleClose"
        fullscreen
      >
        <div>
          <div class="mb-4">
            <h4>ODCs de cliente/agencia</h4>
            <vs-table noDataText="No data" :data="modalData && modalData.purchase_order_client ? modalData.purchase_order_client : []">
              <template slot="thead">
                <vs-th>Archivos</vs-th>
                <vs-th>Fecha creación</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td style="display: flex; flex-direction: row;">
                    <a style="padding-left: 10px;" :href="tr.file" target="_blank">
                      Ver
                    </a>  
                  </vs-td>
                  <vs-td>
                    {{ dateFormatter(tr.created_at) }}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <!-- <div>TOTAL INGRESO: {{ modalData.total_income }}</div> -->
            <div class="p-2 px-4 flex justify-between bg-grey text-white rounded-sm">
              <span class="font-bold">Total ingreso:</span>
              <span class="font-bold">{{modalData && modalData.campaign_price ? modalData.campaign_price : "-" }}</span>
            </div>
          </div>
          <div class="mb-4">
            <h4>ODCs de empresas de medios provisorias</h4>
            <vs-table noDataText="No data" :data="modalData && modalData.purchase_order_media_company_provisory ? modalData.purchase_order_media_company_provisory : []">
              <template slot="thead">
                <vs-th>Empresa de medios</vs-th>
                <vs-th>Archivos</vs-th>
                <vs-th>Fecha creación</vs-th>
                <vs-th>Periodo</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <!-- {{ tr.media_company }} -->
                      Global Argentina
                  </vs-td>
                  <vs-td style="display: flex; flex-direction: row;">
                    <a style="padding-left: 10px;" :href="tr.file" target="_blank">
                      Ver
                    </a>  
                  </vs-td>
                  <vs-td>
                    {{ dateFormatter(tr.created_at) }}
                  </vs-td>
                  <vs-td>
                    <!-- {{ tr.period }} -->
                      17/10/2024 - 07/11/2024
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
          <div class="mb-4">
            <div class="flex justify-between mb-2">
              <h4>ODCs de empresas de medios finales</h4>
              <div class="flex gap-2">
                <button class="bg-latinad-light text-white px-4 py-1 rounded-lg outline-none border-none cursor-pointer text-xs">Generar ODC finales restantes</button>
                <button class="bg-latinad-light text-white px-4 py-1 rounded-lg outline-none border-none cursor-pointer text-xs">Generar ODC mes actual</button>
              </div>
              <!-- move to new component -->
            </div>
            <h5 class="text-center p-2 bg-grey-light">Noviembre - 2024</h5>
            <vs-table noDataText="No data" :data="modalData && modalData.purchase_order_media_company_final ? modalData.purchase_order_media_company_final : []">
              <template slot="thead">
                <vs-th>Empresa de medios</vs-th>
                <vs-th class="center">Archivos</vs-th>
                <vs-th>Fecha creación</vs-th>
                <vs-th>Periodo</vs-th>
                <vs-th>Monto</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <!-- {{ tr.media_company }} -->
                      Global Argentina
                  </vs-td>
                  <vs-td class="flex gap-4">
                    <a style="padding-left: 10px;" :href="tr.file" target="_blank">
                      Ver
                    </a>
                    <a style="padding-left: 10px;" class="cursor-pointer">
                      Cerrar
                    </a>
                    <a style="padding-left: 10px;" class="cursor-pointer">
                      Enviar email
                    </a>
                  </vs-td>
                  <vs-td>
                    {{ dateFormatter(tr.created_at) }}
                  </vs-td>
                  <vs-td>
                    <!-- {{ tr.period }} -->
                      17/10/2024 - 07/11/2024
                  </vs-td>
                  <vs-td>
                    <!-- {{ tr.amount }} -->
                    <strong>2.885.888,10 ARS</strong>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <h5 class="text-center p-2 bg-grey-light">Diciembre - 2024</h5>
            <vs-table noDataText="No data" :data="modalData && modalData.purchase_order ? modalData.purchase_order : []">
              <template slot="thead">
                <vs-th>Empresa de medios</vs-th>
                <vs-th class="center">Archivos</vs-th>
                <vs-th>Fecha creación</vs-th>
                <vs-th>Periodo</vs-th>
                <vs-th>Monto</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <!-- {{ tr.media_company }} -->
                      Global Argentina
                  </vs-td>
                  <vs-td class="flex gap-4">
                    <a style="padding-left: 10px;" :href="tr.file" target="_blank">
                      Ver
                    </a>
                    <a style="padding-left: 10px;" class="cursor-pointer">
                      Cerrar
                    </a>
                    <a style="padding-left: 10px;" class="cursor-pointer">
                      Enviar email
                    </a>
                  </vs-td>
                  <vs-td>
                    {{ dateFormatter(tr.created_at) }}
                  </vs-td>
                  <vs-td>
                    <!-- {{ tr.period }} -->
                      17/10/2024 - 07/11/2024
                  </vs-td>
                  <vs-td>
                    <!-- {{ tr.amount }} -->
                    <strong>2.885.888,10 ARS</strong>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <div class="p-2 px-4 flex justify-between bg-grey text-white rounded-sm">
              <span class="font-bold">Total gasto:</span>
              <span class="font-bold">{{modalData && modalData.total_medios_company_odc_amount ? modalData.total_medios_company_odc_amount : "-" }}</span>
            </div>
          </div>
          <div class="p-2 px-4 flex justify-between bg-latinad text-white rounded-sm">
            <span class="font-bold">Total</span>
            <span class="font-bold" title="Total ingreso - Total gasto">{{modalData && modalData.total_amount ? modalData.total_amount : "-" }}</span>
          </div>
        </div>
      </vs-popup>
    </div>
</template>

<style>
  .center .vs-table-text {
    justify-content: center !important;
  }
</style>

<script>
import { dateFormatter } from '../../utils/strings';

export default {
    name: "show-content-display-detail",
    props: {
        isModalActive: {
          type: Boolean,
          default: false
        },
        modalData: {
          type: Object,
          default: null
        }
    },
    data() {
      return {
        dateFormatter,
      }
    },
    methods: {
        handleClose() {
            this.$emit('update:isModalActive', false); // Notifica al componente padre
        }
    },
}


</script>