var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "centerx" },
    [
      _c(
        "vs-popup",
        {
          attrs: {
            title: "Detalle",
            active: _vm.isModalActive,
            fullscreen: "",
          },
          on: {
            "update:active": function ($event) {
              _vm.isModalActive = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "mb-4" },
              [
                _c("h4", [_vm._v("ODCs de cliente/agencia")]),
                _c(
                  "vs-table",
                  {
                    attrs: {
                      noDataText: "No data",
                      data:
                        _vm.modalData && _vm.modalData.purchase_order_client
                          ? _vm.modalData.purchase_order_client
                          : [],
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ data }) {
                          return _vm._l(data, function (tr, indextr) {
                            return _c(
                              "vs-tr",
                              { key: indextr },
                              [
                                _c(
                                  "vs-td",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-direction": "row",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticStyle: { "padding-left": "10px" },
                                        attrs: {
                                          href: tr.file,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  Ver\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("vs-td", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.dateFormatter(tr.created_at)) +
                                      "\n              "
                                  ),
                                ]),
                              ],
                              1
                            )
                          })
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c("vs-th", [_vm._v("Archivos")]),
                        _c("vs-th", [_vm._v("Fecha creación")]),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "p-2 px-4 flex justify-between bg-grey text-white rounded-sm",
                  },
                  [
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v("Total ingreso:"),
                    ]),
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v(
                        _vm._s(
                          _vm.modalData && _vm.modalData.campaign_price
                            ? _vm.modalData.campaign_price
                            : "-"
                        )
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mb-4" },
              [
                _c("h4", [_vm._v("ODCs de empresas de medios provisorias")]),
                _c(
                  "vs-table",
                  {
                    attrs: {
                      noDataText: "No data",
                      data:
                        _vm.modalData &&
                        _vm.modalData.purchase_order_media_company_provisory
                          ? _vm.modalData.purchase_order_media_company_provisory
                          : [],
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ data }) {
                          return _vm._l(data, function (tr, indextr) {
                            return _c(
                              "vs-tr",
                              { key: indextr },
                              [
                                _c("vs-td", [
                                  _vm._v(
                                    "\n                  Global Argentina\n              "
                                  ),
                                ]),
                                _c(
                                  "vs-td",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-direction": "row",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticStyle: { "padding-left": "10px" },
                                        attrs: {
                                          href: tr.file,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  Ver\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("vs-td", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.dateFormatter(tr.created_at)) +
                                      "\n              "
                                  ),
                                ]),
                                _c("vs-td", [
                                  _vm._v(
                                    "\n                  17/10/2024 - 07/11/2024\n              "
                                  ),
                                ]),
                              ],
                              1
                            )
                          })
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c("vs-th", [_vm._v("Empresa de medios")]),
                        _c("vs-th", [_vm._v("Archivos")]),
                        _c("vs-th", [_vm._v("Fecha creación")]),
                        _c("vs-th", [_vm._v("Periodo")]),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mb-4" },
              [
                _c("div", { staticClass: "flex justify-between mb-2" }, [
                  _c("h4", [_vm._v("ODCs de empresas de medios finales")]),
                  _c("div", { staticClass: "flex gap-2" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "bg-latinad-light text-white px-4 py-1 rounded-lg outline-none border-none cursor-pointer text-xs",
                      },
                      [_vm._v("Generar ODC finales restantes")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass:
                          "bg-latinad-light text-white px-4 py-1 rounded-lg outline-none border-none cursor-pointer text-xs",
                      },
                      [_vm._v("Generar ODC mes actual")]
                    ),
                  ]),
                ]),
                _c("h5", { staticClass: "text-center p-2 bg-grey-light" }, [
                  _vm._v("Noviembre - 2024"),
                ]),
                _c(
                  "vs-table",
                  {
                    attrs: {
                      noDataText: "No data",
                      data:
                        _vm.modalData &&
                        _vm.modalData.purchase_order_media_company_final
                          ? _vm.modalData.purchase_order_media_company_final
                          : [],
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ data }) {
                          return _vm._l(data, function (tr, indextr) {
                            return _c(
                              "vs-tr",
                              { key: indextr },
                              [
                                _c("vs-td", [
                                  _vm._v(
                                    "\n                  Global Argentina\n              "
                                  ),
                                ]),
                                _c("vs-td", { staticClass: "flex gap-4" }, [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { "padding-left": "10px" },
                                      attrs: {
                                        href: tr.file,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Ver\n                "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "cursor-pointer",
                                      staticStyle: { "padding-left": "10px" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Cerrar\n                "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "cursor-pointer",
                                      staticStyle: { "padding-left": "10px" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Enviar email\n                "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("vs-td", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.dateFormatter(tr.created_at)) +
                                      "\n              "
                                  ),
                                ]),
                                _c("vs-td", [
                                  _vm._v(
                                    "\n                  17/10/2024 - 07/11/2024\n              "
                                  ),
                                ]),
                                _c("vs-td", [
                                  _c("strong", [_vm._v("2.885.888,10 ARS")]),
                                ]),
                              ],
                              1
                            )
                          })
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c("vs-th", [_vm._v("Empresa de medios")]),
                        _c("vs-th", { staticClass: "center" }, [
                          _vm._v("Archivos"),
                        ]),
                        _c("vs-th", [_vm._v("Fecha creación")]),
                        _c("vs-th", [_vm._v("Periodo")]),
                        _c("vs-th", [_vm._v("Monto")]),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c("h5", { staticClass: "text-center p-2 bg-grey-light" }, [
                  _vm._v("Diciembre - 2024"),
                ]),
                _c(
                  "vs-table",
                  {
                    attrs: {
                      noDataText: "No data",
                      data:
                        _vm.modalData && _vm.modalData.purchase_order
                          ? _vm.modalData.purchase_order
                          : [],
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ data }) {
                          return _vm._l(data, function (tr, indextr) {
                            return _c(
                              "vs-tr",
                              { key: indextr },
                              [
                                _c("vs-td", [
                                  _vm._v(
                                    "\n                  Global Argentina\n              "
                                  ),
                                ]),
                                _c("vs-td", { staticClass: "flex gap-4" }, [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { "padding-left": "10px" },
                                      attrs: {
                                        href: tr.file,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Ver\n                "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "cursor-pointer",
                                      staticStyle: { "padding-left": "10px" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Cerrar\n                "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "cursor-pointer",
                                      staticStyle: { "padding-left": "10px" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Enviar email\n                "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("vs-td", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.dateFormatter(tr.created_at)) +
                                      "\n              "
                                  ),
                                ]),
                                _c("vs-td", [
                                  _vm._v(
                                    "\n                  17/10/2024 - 07/11/2024\n              "
                                  ),
                                ]),
                                _c("vs-td", [
                                  _c("strong", [_vm._v("2.885.888,10 ARS")]),
                                ]),
                              ],
                              1
                            )
                          })
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c("vs-th", [_vm._v("Empresa de medios")]),
                        _c("vs-th", { staticClass: "center" }, [
                          _vm._v("Archivos"),
                        ]),
                        _c("vs-th", [_vm._v("Fecha creación")]),
                        _c("vs-th", [_vm._v("Periodo")]),
                        _c("vs-th", [_vm._v("Monto")]),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "p-2 px-4 flex justify-between bg-grey text-white rounded-sm",
                  },
                  [
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v("Total gasto:"),
                    ]),
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v(
                        _vm._s(
                          _vm.modalData &&
                            _vm.modalData.total_medios_company_odc_amount
                            ? _vm.modalData.total_medios_company_odc_amount
                            : "-"
                        )
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "p-2 px-4 flex justify-between bg-latinad text-white rounded-sm",
              },
              [
                _c("span", { staticClass: "font-bold" }, [_vm._v("Total")]),
                _c(
                  "span",
                  {
                    staticClass: "font-bold",
                    attrs: { title: "Total ingreso - Total gasto" },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.modalData && _vm.modalData.total_amount
                          ? _vm.modalData.total_amount
                          : "-"
                      )
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }