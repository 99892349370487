<template>
    <div class="currency-select">
        <p>{{label}}</p>
        <v-select
            id="type"
            label="text"
            :options="currencies"
            :clearable="false"
            v-model="currencySelected"
            @input="setCurrencies"
            :disabled="loading"
        >
            <span slot="no-options">Nada que mostrar.</span>
        </v-select>
    </div>
  </template>
  
  <script>
    import vSelect from 'vue-select'
    import { sortAlphabetize } from '@/utils/utils.js'
    import appConfig from '../../../appConfig';
    
  export default {
    components: {
        vSelect
    },
    props: {
        label: {
            type: String,
            default: 'Seleccionar moneda'
        }
    },
    data () {
        return {
            //default value
            currencies: [],
            currencySelected: { value: 'all', text: 'Todas' },
            loading: false
        }
    },
    methods: {
        getCurrencies () {
            this.loading = true
            this.$http.get(`${appConfig.apiUrl}/countries`)
            .then((response) => {
                //mapping response
                const initialCurrencies = response.data.map((country) => ({
                    text: country.default_currency.code,
                    value: country.default_currency.code
                }))
                // Remove duplicates by converting to Set and back to array
                this.currencies = [...new Set(initialCurrencies.map(c => JSON.stringify(c)))].map(str => JSON.parse(str))
                this.currencies = sortAlphabetize(this.currencies, 'text')
                this.currencies.unshift({ value: 'all', text: 'Todas' })

                this.setCurrencies()
            })
            .catch((error) => {
                console.log(error)
            }).finally(() => {
                this.loading = false
            })
        },
        setCurrencies () {
            this.$emit('value', this.currencySelected)
        },
    },
    mounted() {
        this.getCurrencies()
    }
}
</script>
